// https://github.com/hotwired/turbo/issues/34
export async function callTurboStream(url, callback = null) {
  return fetch(
    url,
    {
      method: "GET",
      headers: {
        Accept: "text/vnd.turbo-stream.html",
        "Turbo-Frame": "frame",
      },
    }
  )
  .then((response) => {
    if(callback){
      callback(response);
    }
    return response.text();
  })
  .then((html) => {
    Turbo.renderStreamMessage(html);
  });
}